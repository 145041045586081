@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700');

*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  color: #2d2d2d;
  transition: all 0.15s;
}

body{
  background: #ffffff;
}

a{text-decoration: none;}

#root{
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.bold{
  font-weight: bold;
}

.active{cursor: pointer;}
.action-btn-text {
  font-size: 0.775rem;
  text-transform: uppercase;
  color: #FF3D00;
  font-weight: bold;
}
.action-btn, .action-btn{
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  padding: 0px 24px;
}
.action-btn.active.inverted, .action-btn.active.inverted *{
  color: #FF3D00 ;
  background-color: #fff;
}
.action-btn.active.inverted{border: 1px solid #FF3D00;}
.action-btn.active, .action-btn.active *{
  background-color: #FF3D00 ;
  color: #fff;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
}

.disabled{
  color: gray;
}
