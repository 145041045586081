._dashboardCntr{
  width: 100%;
  flex-grow: 1;
}

.programOverviewTrigger{
  padding: 1rem 2rem;
  border-bottom: 1px solid #ffffff;
}

.programMapCntr{
  background-color: #fff;
  display: flex;
  margin-top: "52.07px"
}
.program-flow-cntr{
  flex-grow: 1;
}
._stepper{
  background-color: inherit !important;
}
.whiteText{
  color: #ffffff !important;
}

.blackText{
  color: rgba(0, 0, 0, 0.87) !important;
  margin-bottom: 0px !important;
}

.step-details-cntr{
  margin: 0rem 3rem;
}

.step-label{
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  padding-left: 1rem;
  display: inline-block;
}

.-step svg circle{
  color:#CECECE
}


.-step.active svg circle{
  color:#FFBC36
}

.step-label-time{
  font-size: 15px;
  margin-left: 1rem;
  line-height: 1.5;
}