@import url('https://fonts.googleapis.com/css?family=Merriweather:900i');
#header-bar{
  height: 100px;
  padding: 0px 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: 0 0.125rem 0.3125rem rgba(0,0,0,.26); */
  border-bottom: 2px solid #dddddd;
  margin-bottom: 5rem;
}

.site-logo-cntr{
  height: 62px;
  display: flex;
  align-items: flex-end;
  margin: 19px 0px;
}

.site-logo-cntr img{
 height: 100%;
}

.site-title{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-left: 8px;
}

.merriwether{
  font-family: 'Merriweather', serif;
}

.red{
  color:#FF3D00;
}

#header-bar a{
  color: #2d2d2d;
  cursor: pointer;
}