.auth-form-cntr{
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.errorMsg{
  height: 1.375em;
}

.login-cntr{
  margin: 4rem auto;
  max-width: 534px;
  padding: 60px 40px 60px 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}