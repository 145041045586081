.capsule-cntr{
  border-top: 1px solid #defdef;
  padding: 1.5rem;
  width: 90%;
  max-width: 1005px;
  margin: auto;
  font-family: 'Nunito', sans-serif;
}

.capsule-blk{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.capsule-left{
  width: 55%;
}

.capsule-right{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.-step{
  border-top: 1px solid #E5E5E5;
}
.capsule-right img{
  max-width: 100%;
}
.action-cntr{display: flex; padding-top: 16px;}
